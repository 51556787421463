@import './variables/colors.scss';

@font-face {
  font-family: "Work Sans";
  src: url("../../fonts/WorkSans-light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("../../fonts/WorkSans-lightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Work Sans";
  src: url("../../fonts/WorkSans-Regular.ttf") format("truetype");
  font-weight: 300 400;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("../../fonts/WorkSans-Medium.ttf") format("truetype");
  font-weight: bold 400;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("../../fonts/WorkSans-SemiBold.ttf") format("truetype");
  font-weight: 500 900;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}


html, body {
  margin: 0;
  padding: 0;
}

* {
  font-family: "Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h2 {
  font-size: 30px;
  font-weight: 400;
}

h4 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  color: #086375;
}

.bold {
  font-weight: bold;
}

.weight-400 {
  font-weight: 400;
}

.cgu-block {
  margin-bottom: 40px;

  h4 {
    margin-top: 12px;
    margin-bottom: 16px;
  }

  h5 {
    font-weight: lighter;
    font-size: 1.1em;
    margin-top: 44px;
    margin-bottom: 44px;
    margin-left: 21px;
  }

  h6 {
    font-weight: bold;
    font-size: 1.1em;
    margin-top: 33px;
    margin-bottom: 33px;
    text-align: center;
  }

  table {
    width: 100%;
    margin: 100px auto;

    tr {
      border: 1px solid;
      padding: 9px;
    }

    th {
      color: white;
      background: black;
      padding: 9px;
      text-align: center;

      &.table-main-th {
        color: white;
        background: $mint;
      }
    }

    td {
      padding: 9px;
      border: 1px solid;
      width: 50%;
    }
  }

  p, li {
    text-align: justify;
  }

  .mdi-circle {
    font-size: 0.6em;
  }
}

h6 {
  font-size: 15px !important;
  font-weight: 300;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 !important;
  overflow-x: hidden;
}


ul {
  li {
    list-style-type: none;
  }
}

a {
  color: #444;
  text-decoration: none;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

a:hover {
  color: #444;
}

a:focus, a:hover {
  color: #23527c;
  text-decoration: underline;
}

a:hover, a:active {
  outline: 0;
}


p, li {
  font-size: 14px;
  font-weight: 200;
  color: rgb(26, 26, 26);
  margin-bottom: 10px;
  list-style: none;
}

.text-underline {
  text-decoration: underline;
}

img {
  max-width: 100%;
}

.program-videos {
  .video {
    background: white;
    box-shadow: 0 0 5px grey;
    padding: 20px 0 0 0;
    border-radius: 3px;

    .embed-container {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

// post cards
.post-card {
  padding-left: 3px;
  padding-right: 3px;
}

@media screen and (min-width: 768px) {
  .post-card, .event-row, .post-item {
    &.col-md-2-4 {
      flex: 0 0 19%;
      width: 19% !important;
      padding: 0;
      margin: 10px 0.5%;
    }
  }
}

.post-card, .event-row, .post-item {

  &.col-md-2-4 {
    width: 19% !important;
    margin: 10px 0.5%;
  }

  .program-item {
    background: white;
    box-shadow: 0 0 5px grey;
    border-radius: 3px;

    .item-card-bg {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    .program-title {
      font-size: 16px;
    }

    .program-description {
      line-height: 1.1em;
      max-height: 3.3em;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      height: 80px;

      p {
        color: #156375;
        margin: 0;
      }
    }

    a {
      text-decoration: none;
      color: $turquoise;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .subcategory-title {
    font-size: 12px;
    font-weight: 400;
  }

  .responsive-post-maincat {
    display: none;

    .badge-category {
      display: inline-block;
      font-size: 9px;
      padding: 4px 8px !important;
      margin-top: -8px;
    }
  }

  p.subcategory-title {
    line-height: 1.1em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    padding-top: 2px;
    height: 28px;
  }

  p.subcategory-title {
    line-height: 1.1em;
  }

  .event-location {
    padding-top: 5px;
    line-height: 0.9em;
  }

  .event-description {
    p.subcategory-title {
      line-height: 1em;
      max-height: 1.6em;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      height: 17px;
    }


    p.post-title {
      line-height: 1em;
      max-height: 2.6em;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      height: 32px;
    }
  }

  .post-card-bg, .item-card-bg {
    background-position: center;
    background-size: cover;
    height: auto;
    position: relative;
    aspect-ratio: 16 / 9;
    //&.taller {
    //  height: 176px;
    //}

    // fallback
    @supports not (aspect-ratio: auto) {
      height: 92px;
    }


    //gestion de l'overlay
    .grey-overlay {
      display: none;
      background-color: $grey-500;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    i, .icon-hover-post {
      display: none;
    }

    &:hover {
      display: flex;
      justify-content: center;
      align-items: center;

      .grey-overlay {
        display: block;
      }

      i, .icon-hover-post {
        color: white;
        z-index: 2;
        display: block;
      }
    }
  }

  img {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .post-title {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.1em;
    color: #086375;
    padding: 0 0 0 0;
    border-radius: 0;
    background: transparent;
    z-index: 2;
    display: block;
    font-family: Work Sans, Helvetica, sans-serif;
  }
}

.event-row {
  p.post-title {
    max-height: 2.9em !important;
    height: 35px !important;
  }
}

@media screen and (max-width: 768px) {
  .event-row {
    p.post-title {
      max-height: 3em !important;
      height: 36px !important;
    }
  }
}

.badge-category {
  color: #fff;
  font-weight: 600;
  padding: 8px 24px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  justify-content: center;
  max-width: 151px;

  &.longer {
    max-width: 300px;
  }

  p {
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 0;

    &.bigger {
      line-height: 14px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

//main title
.main-title {
  border-bottom-width: 2px !important;
  padding-bottom: 16px !important;
  border-bottom-style: solid !important;

  position: relative;
  margin: 0 0 40px 0;

  width: fit-content;
  z-index: 2;

}

.main-title-hr {
  position: relative;
  top: -41px;
  height: hairline;
  content: '';
  z-index: 1;
  margin: 0;
  width: 100%;
  //border-top-color: $grey-500 !important;

  &.white {
    border-top-color: white !important;
  }
}

@media screen and (max-width: 768px) {
  //main title
  .main-title {
    border-bottom-width: 2px !important;
    padding-bottom: 16px !important;
    border-bottom-style: solid !important;

    position: relative;
    margin: 0 0 20px 0;

    width: fit-content;
    z-index: 2;

  }

  .main-title-hr {
    position: relative;
    top: -21px;
    height: hairline;
    content: '';
    z-index: 1;
    margin: 0;
    width: 100%;
    //border-top-color: $grey-500 !important;

    &.white {
      border-top-color: white !important;
    }
  }
}


// padding due to header
@media only screen and (min-width: 1140px) {
  .content {
    padding-top: 98px;
  }
}

@media only screen and (min-width: 320px) {
  .main-title {
    font-size: 18px !important;
  }

  .content {
    padding-top: 100px;
  }
}


// redefine containers styles
@media (min-width: 576px) {
  .container {
    max-width: 556px !important;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 750px !important;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 970px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px !important;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1220px !important;
  }
}

@media only screen and (min-width: 1460px) {
  .container {
    max-width: 1400px !important;
  }
}


.light-grey-bg {
  background-color: $light-grey;
}

// some flex classes
.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

//font classes
.font-16 {
  font-size: 16px;
}

//other helpers
.fit-content {
  width: fit-content;
}

.bg-mint {
  background-color: #1cd4b0 !important;
}

.color-mint {
  color: #1cd4b0 !important;
}

.color-turquoise {
  color: $turquoise !important;
}

.mint-btn {
  border: 1px solid $mint;
  border-radius: 6px;

  button {
    transition: all 0.3s ease-in;

    background-color: $mint;
    border: none;
    outline: none;
    padding: 10px 20px;
    width: 100%;

    &:disabled {
      background-color: $grey-500;

      &:hover {
        a, p, span {
          color: $grey-500;
          text-decoration: none;
        }
      }
    }

    a, p, span {
      color: white;
      transition: all 0.3s ease-in;
    }

  }

  &.border-radius {
    border-radius: 5px;

    button {
      padding: 2px 12px;
      border-radius: 5px;
    }
  }

  &:hover {
    &.mint-outline {
      border: 1px solid $mint;
    }

    button:not(:disabled) {
      background-color: transparent;

      a, p, span {
        color: $mint;
        text-decoration: none;
      }
    }
  }

  &.fit-content {
    max-width: 250px;
  }
}


.mint-btn-2:hover {
  button {
    background-color: $turquoise;

    a {
      color: white;
      text-decoration: none;
    }
  }
}


//events cards
.event-row {
  display: flex;
  height: 88px;
  margin-bottom: 15px;
  overflow: hidden;

  .subcategory-title {
    font-size: 13.5px;
  }

  p {
    margin-bottom: 0;
  }

  .event-date {
    flex: 3;

    p {
      color: white;
    }
  }

  .event-description {
    background-color: white;
    flex: 7;
    padding: 6px 10px;
  }
}

//
.embed-container {
  position: relative;
  padding-bottom: 56.25%;

  overflow: hidden;
  max-width: 786px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  width: 100%;
  height: 100%;
}


// filters
.filter-item {
  display: inline-block;
  cursor: pointer;
  font-size: 10px;
  border-bottom: 1px solid $intermediate-grey;
  line-height: 36px;

  i {
    display: none;
  }

  &.active {
    color: $mint !important;
    border-bottom: 1px solid $mint;

    i {
      color: $mint;
      display: inline-block;
    }
  }

  &:hover {

    i {
      color: $mint;
    }
  }
}

.post-item {
  transition: all 1s ease-in;
}

.ratio-16-9 {
  aspect-ratio: 16/9;
}

// carousel
.carousel {
  width: 100%;
}

.carousel-control-left, .carousel-control-right {
  position: absolute;
  z-index: 20;
  bottom: 40%;
  font-weight: bold;

  i {
    color: #000;
  }
}

.carousel-control-left {
  left: -26px;
}

.carousel-control-right {
  right: -26px;
}

//leaflet {
.leaflet-popup-pane {
  width: 200px;
}

.leaflet-popup {
  width: 200px;
}

.leaflet-popup-content {
  margin: 0 !important;
}

.leaflet-popup-content-wrapper {
  border-radius: 0 !important;
}

.map-popup-img {
  max-height: 56px;
}

.map-link {
  text-decoration: none !important;
  color: $turquoise !important;

  line-height: 1em;
  max-height: 9em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  height: 54px;
  margin-bottom: 10px;
  padding-right: 20px;
  margin-top: -8px;
  visibility: visible;

  p {
    font-size: 11px;
  }

  &:hover p {
    color: $mint !important;
  }

}

.alert-container {
  z-index: 909090;
}


// Alerts
.alert-container {
  position: fixed !important;
  top: unset !important;
  left: unset !important;
  bottom: 10px !important;
  right: 10px !important;
  max-width: 600px;
  //z-index: 9999 !important;
}

.contact-me-container {
  width: 60%;
  background: $turquoise;
  padding: 26px 40px;
  border-radius: 10px;
  margin: auto auto -100px;

  p {
    text-align: center;
    font-size: 18px;
    margin-bottom: 0;
    color: white;
  }
}

//brand logo
.post-brand-logo {
  position: absolute;
  right: 7px;
  top: 1px;
  opacity: 0.8;
  max-width: 46px;
  max-height: 26px;
  filter: brightness(0%) invert(100%);
}

@supports not (aspect-ratio: auto) {
  .post-card .post-card-bg {
    height: 200px;
  }
}


//responsive classes
@media (max-width: 768px) {
  //.main-title {
  //  font-size: 26px !important;
  //}
  //.category-section .featured .featured-title {
  //  font-size: 26px !important;
  //}

  .header_nav.row .logo img {
    max-height: 60px;
  }

  .post-card, .event-description {
    p.subcategory-title {
      max-height: 1.23em !important;
      height: 29px !important;
      -webkit-line-clamp: 1;
    }

  }
  .post-card, .post-item {
    //.subcategory-title {
    //  font-size: 23px;
    //}

    .subcategory-title {
      margin: 0;
    }

    .post-title {
      font-size: 19px;
    }
  }

  .event-row .post-title {
    font-size: 14px;
  }

  .connexion-container {
    flex: 1;
    max-width: 100%;
    margin-left: 0;
    padding: 0 40px;
  }

  .responsive-post-maincat {
    display: initial !important;
    max-height: 29px;
    margin-right: 4px;
  }

}

.cursor-pointer {
  cursor: pointer;
}

.newsletter-bg {
  background-color: $turquoise;

  .newsletter {
    min-height: 110px;
    padding: 2em 3em;


    .borders {
      flex: 2;
      border-right: 1px solid white;
      border-left: 1px solid white;
      margin-right: 20px;
      padding-right: 10px;
      margin-left: 20px;
      padding-left: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  //newsletter
  .flexed-container {
    flex-direction: column !important;

    h4 {
      text-align: center;
    }

    .borders {
      border-right: 0 !important;
      border-left: 0 !important;

      margin-top: 30px;
      margin-bottom: 30px;
    }

    .flex-3 {
      width: 100%;
    }
  }
}

//colors for mainCategories
.bg-color-societe {
  background-color: #9e1846;
}

.bg-color-economie {
  background-color: #7d2071;
}

.bg-color-sante {
  background-color: #31a0e3;
}

.bg-color-environnement {
  background-color: #39aa34;
}

.bg-color-tourisme {
  background-color: #e38a10;
}

.bg-color-culture {
  background-color: #d4641b;
}

.bg-color-no-category {
  background-color: #686868;
}

.bg-color-sport {
  background-color: #c5182e;
}

.bg-color-technologie {
  background-color: #075a7d;
}
